import React from 'react';
import { Fade } from "react-awesome-reveal";
import PropTypes from 'prop-types';

const BlogTitle = ({ title, isBlog = false }) => (
  <Fade triggerOnce direction='down' duration={1000} delay={300}>
    <h1 className={isBlog ? "blog-title" : "section-title"}>{title}</h1>
  </Fade>
);

BlogTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BlogTitle;